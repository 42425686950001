import React from 'react'

// COmponents
import ServicesBlock from 'components/services-block'

function ProblemSolving() {
  return (
    <ServicesBlock
      title="Consultancy"
      subtitle="Finding a winning app concept isn't always easy. Get some expertise and insight to help you find clarity and confidence"
      items={[
        {
          title: 'User Research',
          text: 'Conduct unbiased research and learn exactly where you can bring value to customers and employees.',
          url: '/services/user-research',
        },
        {
          title: 'Mobile Strategy',
          text: 'Get support in finding the best way to use mobile apps, push messaging and location services.',
          url: '/services/strategy',
        },
        {
          title: 'Journey Maps',
          text: 'Understand how customers perceive interacting with your organisation at every touch point.',
        },
        {
          title: 'Technology Guidance',
          text: 'Learn which mobile technologies are the best fit for your business. From PWAs to native mobile apps.',
        },
        {
          title: 'User Testing',
          text: 'Discover what customers think of your current app or website, then highlight areas for improvement.',
        },
        {
          title: 'Competitor Analysis',
          text: 'Hone your strategy by learning where you excel and see where the gaps are.',
        },
      ]}
    />
  )
}

export default ProblemSolving
