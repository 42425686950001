import React from 'react'

// COmponents
import ServicesBlock from 'components/services-block'

function DeliverApps() {
  return (
    <ServicesBlock
      title="Development Partnerships"
      subtitle="Design and launch apps, websites and digital products. Always working interatively and putting your customers center-stage."
      items={[
        {
          title: 'User Experience Design',
          text: 'Satisfy customers by taking a user-centred approach to designing your mobile apps and digital products.',
          url: '/services/app-ux-design',
        },
        {
          title: 'App Design',
          text: 'Modern mobile design for tablets and smartphones resulting in a quality, easy and engaging app.',
        },
        {
          title: 'Mobile App Development',
          text: 'Robust and secure iOS apps, Android apps and cross-platform Flutter apps.',
          url: '/services/mobile-app-development',
        },
        {
          title: 'Data & Cloud Solutions',
          text: 'Scalable and secure websites, portals and APIs. All the infrastructure you need to support your app.',
        },
        {
          title: 'Progressive Web Apps',
          text: 'Fast, responsive mobile and desktop apps built to make use of modern browser features.',
        },
        {
          title: 'IoT App Development',
          text: 'Apps and embedded touchscreen interfaces for device and instrument manufacturers.',
        },
      ]}
    />
  )
}

export default DeliverApps
