import React from 'react'

// Components
import ServicesBlock from 'components/services-block'

function TrustUs() {
  return (
    <ServicesBlock
      title="Data-driven Growth"
      subtitle="Turn your app into a genuine business asset with KPIs that can be measured. Trust us to monitor, maintain and update your app so that it is always available to customers and employees."
      items={[
        {
          title: 'Hosting and Monitoring',
          text: 'Host your app on robust, secure and scalable Amazon infrastructure, monitored by our team.',
        },
        {
          title: 'App Store Optimisation',
          text: 'Over 60% of apps are found via search, so we ensure that your app store pages are optimised.',
        },
        {
          title: 'App Analytics',
          text: 'Put product metrics in place to understand where are engaged and where they drop off.',
        },
        {
          title: 'Continous Improvement',
          text: 'Find where your app is satisfying customers and get suggestions for improvement where needed.',
        },
        {
          title: 'Marketing Technologies',
          text: 'Create retention and re-engagement campaigns based on analytics and data.',
        },
        {
          title: 'App Store Updates',
          text: 'Proactively keep your app up to date and avoid downtime when operating systems are updated.',
        },
      ]}
    />
  )
}

export default TrustUs
